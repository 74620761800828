@import "~@angular/material/theming";
$font-path: "../fonts";
@import "./assets/styles/variables";
@import "./assets/styles/font-files";
@import "./assets/styles/resets";

// partials
@import "./scss/auth-forms";

// Common styles for Angular Material. Included so that we only have to load a
// single CSS file for Angular Material in our app.
// Don't include this anywhere else!
@include mat-core($custom-typography);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($candy-app-theme);

// @include mat-base-typography($custom-typography);

// Responsive type maybe?
@media screen and (max-width: 767px) {
  @include mat-base-typography($mobile-typography);
}

a {
  color: $amaranth;
  font-weight: 500;
}

.active-route {
  font-weight: 700;
  background-color: mat-color($mat-gray, 100);
}

.card-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax((334px), 1fr));
  grid-gap: 24px;
}

.mat-stroked-button {
  background-color: #fefefe;
}

header.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -16px;
  margin-bottom: 20px;

  > * {
    margin: 0;
  }
}

.l-body {
  max-width: 1100px;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;

  &--full-width {
    max-width: 100%;
  }

  @media screen and (max-width: $small - 1) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.u-hidden {
  display: none;
}

.mat-display-1,
.mat-display-2 {
  margin-bottom: 1.2em;
}

.marginless {
  margin: 0;
}

.full-width {
  width: 100%;
}
